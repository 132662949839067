















































import { Component, Vue } from 'vue-property-decorator'
import NewsPreview from '@/components/content/NewsPreview.vue'
import ContentFilterButtonGroup from '@/components/common/buttons/ContentFilterButtonGroup.vue'
import ContentAPI from '@/api/requests/content'
import {
  PermissionManager,
  permissions
} from '@/api/permissions/PermissionManager'
import PaginationMixin from '@/mixins/paginationMixin'
import { contentStatus, contentType } from '@/config/content/contentStatus'

@Component({
  components: {
    NewsPreview,
    ContentFilterButtonGroup
  },
  mixins: [PaginationMixin]
})
export default class AllNews extends Vue {
  private newsData: object[] = []
  private showSpinner = true
  private nothingToDisplay = false
  private contentStatus_ = contentStatus
  private status = this.contentStatus_.all
  private contentType_ = contentType

  private isShowMore!: boolean
  private fetchNext!: Function
  private resetPagination!: Function

  private viewContentManagerButtons = false

  private async filterStatusChanged(filterStatus: any) {
    this.status = filterStatus
    this.newsData = []
    this.resetPagination()
    await this.showMore()
  }

  private async showMore() {
    this.showSpinner = true
    this.isShowMore = false
    this.newsData = await this.fetchNext(
      ContentAPI.getNews,
      this.viewContentManagerButtons
        ? this.status
        : this.contentStatus_.publicated
    )
    this.showSpinner = false
    this.nothingToDisplay = this.newsData.length === 0
  }

  private async created() {
    const ability = PermissionManager.common()
    this.viewContentManagerButtons = ability.can(
      permissions.viewContentManagerButtons,
      undefined
    )
    this.showMore()
  }
  private onAddNewsClick() {
    this.$router.push('/news/new')
  }
}
