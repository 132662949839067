








































































import { Component, Prop, Vue } from 'vue-property-decorator'
import LikeEditor from '@/components/content/helpers/LikeEditor.vue'
import { imagePreview } from '@/config/default/defaultImages'
import { ContentAdapter } from '@/api/adapters/content'
import {
  PermissionManager,
  permissions
} from '@/api/permissions/PermissionManager'
import {
  contentStatusBadges,
  contentStatusTranslation
} from '@/config/content/contentStatus'

@Component({
  components: { LikeEditor }
})
export default class NewsPreview extends Vue {
  @Prop(Object) news: any
  private canViewContentStatus = false
  private hover = false
  private imagePreview_ = imagePreview
  private contentStatusTranslation_ = contentStatusTranslation
  private contentStatusBadges_ = contentStatusBadges

  $refs!: {
    previewImage: HTMLImageElement
  }

  private previewHeight = 250

  private created() {
    const ability = PermissionManager.common()
    this.canViewContentStatus = ability.can(
      permissions.viewContentStatus,
      undefined
    )
    this.news.avatarUrl = ContentAdapter.adaptAvatarUrl(this.news.avatarUrl)

    if (this.news.eventDate) {
      this.news.eventDate = ContentAdapter.changeDateFormat(
        this.news.type,
        this.news.eventDate
      )
    }
  }

  private onPreviewLoad(news: any) {
    this.previewHeight = news.target.clientWidth * 0.5625 // 0.5625 = 9/16 => Соотношение сторон: 16:9
  }

  private onHover(hovered: boolean) {
    this.hover = hovered
  }

  private onPreviewClick() {
    this.$router.push(`/news/${this.news.id}`)
  }
}
